import React, { useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import "./App.css";
const data = [
  { option: 'Закрыть FS' },
  { option: 'Снять всю ГА' },
  { option: 'Починить метрострой' },
  { option: 'Начать что-то делать' },
  { option: 'Выпустить троллейбус' },
  { option: 'Посмотреть в тикеты' },
  { option: 'Задонатить в FS' },
  { option: 'Починить лобби' },
  { option: 'Сделать заебись' },
  { option: 'Посмотреть сериал' },
  { option: 'Зайти в дискорд' },
  { option: 'Прийти на собрание' },
  { option: 'Записать кружочек' },
  { option: 'Попить чай' },
  { option: 'Перестать выебываться' },
  { option: 'Доделать RP' },
  { option: 'Решить задачи' },
  { option: 'Ответить Смирнову' },
]

export default () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  }

  return (
      <div style={{width: '100%', margin: 0}}>
        <h2 style={{textAlign: 'center', color: '#ff0000', textShadow: "2px 2px black"}}>Diablus [RUS]</h2>
        <h2 style={{textAlign: 'center'}}>ЧТО МНЕ ДЕЛАТЬ?</h2>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              backgroundColors={['#b6aeae', '#277c29']}
              innerRadius={5}
              innerBorderWidth={1}
              fontSize={12}
              onStopSpinning={() => {
                setMustSpin(false);
              }}
          />
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <button
              className={"spinbutton"}
              onClick={handleSpinClick}>Мне повезет</button>
        </div>

      </div>
  )
}